import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/page/linkbutton.css"

class ShopPage extends React.Component {

  state = { 
    tagsOn: [],
    tagsOpen: false 
  }

  openShopPage () {
    // window.open('https://marpple.shop/kr/yalco/', '_blank')
    window.open('https://marpple.shop/kr/yalco', '_blank')
  }

  render () {
    return (
      <Layout page="Shop">
        <SEO title="홈"/>
        <div id="smartstore-link-container">
          <div
            role="button"
            className="link"
            onClick={ () => this.openShopPage }
            onKeyDown={ () => this.openShopPage }
            tabIndex={0}
          >
            스토어 바로가기
          </div>
        </div>
      </Layout>
    )
  }

  componentDidMount () {
    // window.location.replace('https://marpple.shop/kr/yalco/')
    window.location.replace('https://marpple.shop/kr/yalco')
  }
}


// const ShopsPage = () => {
// }

export default ShopPage
